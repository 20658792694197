import model from './model';
import { FastGalleryPluginEvents as Events, } from '../../constants/generalConstants';

export default model.createController(
  ({ initState, $bind, $widget, flowAPI, $w }) => {
    $widget.onPropsChanged((_, newProps) => {
      const { items } = newProps;

      // @ts-expect-error
      $w('#fastGallery1').items = items;
      $w('#expandModPolyWidget1').items = items;
    });

    const initWidget = async () => {
      // @ts-expect-error
      $w('#fastGallery1').items = $widget.props.items || [];
      $w('#expandModPolyWidget1').items = $widget.props.items || [];

      // @ts-expect-error
      $w('#fastGallery1').onItemClicked((event) => {
        const { itemIndex } = event;

        $w('#expandModPolyWidget1').currentIndex = itemIndex;
        // @ts-expect-error
        $w('#fastGallery1').setExpandOpen(true);
      });

      // @ts-expect-error
      $w('#fastGallery1').onGetMoreItems(async () => {
        $widget.fireEvent(Events.GetMoreItems, {});
      });

      $w('#expandModPolyWidget1').onCloseExpandModeClicked(() => {
        // @ts-expect-error
        $w('#fastGallery1').setExpandOpen(false);
      });
    };

    return {
      pageReady: async () => {
        initWidget();
      },
      exports: {},
    };
  },
);
