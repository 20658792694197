import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { createPolymorphismUtils } from '../utils/polymorphismUtils';

export const editorAppEventListener = async (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
) => {
  await editorSDK.addEventListener(
    'globalDesignPresetChanged',
    async (event) => {
      await editorSDK.document.transactions.runAndWaitForApproval(
        '',
        async () => {
          try {
            const { preset, componentRef } = event.detail;
            const { parsePolyPresetId, changePolyPreset } =
              createPolymorphismUtils(editorSDK);

            const { widgetId, presetId } = parsePolyPresetId(preset);

            await changePolyPreset({ componentRef, presetId, widgetId });
          } catch (e) {
            console.error('Error in globalDesignPresetChanged event', e);
          }
        },
      );
    },
  );
};
