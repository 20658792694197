import {
  EditorScriptFlowAPI,
  FlowEditorSDK,
  FlowPlatformOptions,
} from '@wix/yoshi-flow-editor';
import { editorAppEventListener } from './editorAppEventListener';
import {
  appDefinitionId,
  fastGalleryPlugin1WidgetId,
  fastGalleryPolyPluginSlotName,
  fastGalleryPolyPluginWidgetId,
} from '../constants/generalConstants';

export const onEditorReady = async (
  editorSDK: FlowEditorSDK,
  appDefId: string,
  options: FlowPlatformOptions,
  flowAPI: EditorScriptFlowAPI,
): Promise<void> => {
  await editorSDK.addEventListener('componentAddedToStage', async (event) => {
    const serializedComponent = await editorSDK.components.serialize('token', {
      componentRef: event.detail.compRef,
    });

    if (serializedComponent?.data?.widgetId === fastGalleryPolyPluginWidgetId) {
      await editorSDK.tpa.widgetPlugins.addWidgetPlugin('', {
        widgetPluginPointer: {
          appDefinitionId,
          widgetId: fastGalleryPlugin1WidgetId,
        },
        slotId: fastGalleryPolyPluginSlotName,
        widgetId: fastGalleryPolyPluginWidgetId,
      });
    }
  });

  // TODO: switch to new presets panel without events
  editorAppEventListener(editorSDK, flowAPI);
};
