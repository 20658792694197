import { WidgetPropertyType } from '@wix/yoshi-flow-editor/build/cjs/blocks';

export const appDefinitionId = '785199b4-c741-4d19-ad2f-060695514c78';

export const ExpandModPolyWidget = '5ae9a59b-f087-4fde-9a45-137af3d9b11f';

export const fastGalleryPolyPluginWidgetId =
  '2b1e82f2-b7d0-455a-826f-144a74d3d4db';

export const fastGalleryPolyPluginSlotName = 'fastGalleryPolyPluginSlot';

export const fastGalleryPlugin1WidgetId =
  '6ff60708-7627-46f6-9db7-f7be15e2c8e2';

export const galleryItemsType = {
  type: [] as any,
  title: 'Gallery Items',
  defaultValue: [],
};

export const expandModePluginProps = {
  // Here you can define your widget props
  items: galleryItemsType,
  currentIndex: {
    type: WidgetPropertyType.NUMBER,
    title: 'Gallery Id',
    defaultValue: -1,
  },
};

export enum FastGalleryPluginEvents {
  GetMoreItems = 'getMoreItems',
}

export enum ExpandModeMainWidgetEvents {
  CloseExpandModeClicked = 'closeExpandModeClicked',
}
