import { appDefinitionId } from '../constants/generalConstants';
import { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { isPresetApplied, resetOverridesAndSetPreset } from './editorUtils';

type SlotData = {
  compRef: ComponentRef;
  role: string;
  interfaces: string[];
  placement: {
    appDefinitionId: string;
    widgetId: string;
    slotId: string;
  };
  pluginInfo?: {
    widgetId: string;
    appDefinitionId: string;
    name: string;
    description: string;
    logoUrl: string;
  };
};

type SwitchSlotWidgetPlugin = (params: {
  slotCompRef: ComponentRef;
  newPluginId: string;
}) => Promise<void>;

type GetSlotData = (widgetRef: ComponentRef) => Promise<SlotData | void>;

type GetSlotInnerWidgetData = (params: { slotData: SlotData }) => Promise<{
  slotInnerWidgetRef: ComponentRef;
  slotWidgetPluginId?: string;
} | void>;

type GetPresetIdByComponentRef = (params: {
  componentRef: ComponentRef;
}) => Promise<string | undefined>;

type ChangePolyPreset = (params: {
  componentRef: ComponentRef;
  widgetId: string;
  presetId: string;
}) => Promise<void>;

type ParsePolyPresetId = (polyPresetId: string) => {
  externalPresetPrefix: string;
  widgetId: string;
  presetId: string;
};
type BuildPolyPresetId = (params: {
  presetWidgetPluginId: string;
  variantId: string;
}) => string;

interface PolymorphismUtils {
  switchSlotWidgetPlugin: SwitchSlotWidgetPlugin;
  getSlotData: GetSlotData;
  getSlotInnerWidgetData: GetSlotInnerWidgetData;
  parsePolyPresetId: ParsePolyPresetId;
  buildPolyPresetId: BuildPolyPresetId;
  getPresetIdByComponentRef: GetPresetIdByComponentRef;
  changePolyPreset: ChangePolyPreset;
}

export const createPolymorphismUtils = (
  editorSDK: FlowEditorSDK,
): PolymorphismUtils => {
  const switchSlotWidgetPlugin: SwitchSlotWidgetPlugin = async ({
    slotCompRef,
    newPluginId,
  }) => {
    await editorSDK.tpa.widgetPlugins.removeWidgetPlugin('', {
      slotCompRef,
    });
    await editorSDK.tpa.widgetPlugins.addWidgetPlugin('', {
      widgetPluginPointer: {
        appDefinitionId,
        widgetId: newPluginId,
      },
      slotCompRef,
    });
  };

  const getSlotData: GetSlotData = async (widgetRef) => {
    const slotsData = await editorSDK.tpa.widgetPlugins.getWidgetSlots('', {
      widgetRef,
    });
    if (slotsData?.length > 0) {
      const slotData = slotsData[0];
      return slotData;
    }
  };

  const getSlotInnerWidgetData: GetSlotInnerWidgetData = async ({
    slotData,
  }) => {
    const slotCompRef = slotData?.compRef;
    if (!slotCompRef) {
      return;
    }
    const slotInnerWidgetRef = (
      await editorSDK.components.getChildren('', {
        componentRef: slotCompRef,
      })
    )[0];
    if (slotInnerWidgetRef) {
      const slotWidgetPluginId = slotData.pluginInfo?.widgetId;
      return { slotInnerWidgetRef, slotWidgetPluginId };
    }
  };

  const changePolyPreset: ChangePolyPreset = async ({
    componentRef,
    presetId,
    widgetId,
  }) => {
    const widgetAncestorRef = (
      await editorSDK.components.getAncestors('', {
        componentRef,
      })
    )[0];

    const slotData = await getSlotData(widgetAncestorRef);

    if (!slotData) {
      throw new Error('No slot data found');
    }
    const slotInnerWidgetData = await getSlotInnerWidgetData({
      slotData,
    });
    const currentSlotInnerWidgetRef = slotInnerWidgetData?.slotInnerWidgetRef;
    const currentSlotWidgetPluginId = slotInnerWidgetData?.slotWidgetPluginId;

    if (!currentSlotInnerWidgetRef || !currentSlotWidgetPluginId) {
      throw new Error('No slot inner widget ref or slot widget plugin id');
    }
    const shouldChangePlugin = widgetId !== currentSlotWidgetPluginId;

    if (shouldChangePlugin) {
      await switchSlotWidgetPlugin({
        slotCompRef: slotData.compRef,
        newPluginId: widgetId,
      });

      const newSlotData = await getSlotData(widgetAncestorRef);
      if (!newSlotData) {
        throw new Error('No slot data found');
      }
      const newSlotInnerWidgetData = await getSlotInnerWidgetData({
        slotData: newSlotData,
      });
      const newSlotInnerWidgetRef = newSlotInnerWidgetData?.slotInnerWidgetRef;
      if (!newSlotInnerWidgetRef) {
        throw new Error('No slot inner widget  ref found');
      }

      resetOverridesAndSetPreset({
        editorSDK,
        presetId,
        componentRef: newSlotInnerWidgetRef,
      });
    } else {
      const isWidgetPresetApplied = await isPresetApplied({
        editorSDK,
        presetId: widgetId,
        slotInnerWidgetRef: currentSlotInnerWidgetRef,
      });
      if (!isWidgetPresetApplied) {
        await resetOverridesAndSetPreset({
          editorSDK,
          presetId,
          componentRef: currentSlotInnerWidgetRef,
        });
      }
    }
  };

  const getPresetIdByComponentRef: GetPresetIdByComponentRef = async ({
    componentRef,
  }) => {
    const slotData = await getSlotData(componentRef);
    if (!slotData) {
      throw new Error('No slot data found');
    }

    const slotInnerWidgetData = await getSlotInnerWidgetData({
      slotData,
    });
    if (!slotInnerWidgetData?.slotInnerWidgetRef) {
      throw new Error('No slot inner widget ref');
    }

    const presetValue = await editorSDK.application.appStudioWidgets.getPreset(
      '',
      {
        componentRef: slotInnerWidgetData.slotInnerWidgetRef,
      },
    );

    if (!presetValue) {
      throw new Error('No preset value found');
    }
    return presetValue?.style;
  };

  const parsePolyPresetId: ParsePolyPresetId = (polyPresetId) => {
    const [externalPresetPrefix, widgetId, presetId] = polyPresetId.split('#');
    return { externalPresetPrefix, widgetId, presetId };
  };
  const buildPolyPresetId: BuildPolyPresetId = ({
    presetWidgetPluginId,
    variantId,
  }) => {
    return `externalPreset-#${presetWidgetPluginId}#${variantId}`;
  };
  return {
    switchSlotWidgetPlugin,
    getSlotData,
    getSlotInnerWidgetData,
    getPresetIdByComponentRef,
    parsePolyPresetId,
    buildPolyPresetId,
    changePolyPreset,
  };
};
