import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder) => {
  widgetBuilder.set({
    displayName: 'Widget fastGalleryPlugin1',
    nickname: 'fastGalleryPlugin1Widget',
  });

  widgetBuilder.gfpp().set('widgetPlugins', { behavior: 'HIDE' });
};
