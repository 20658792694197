export function getStringFromNumber(num: number) {
  if (num < 10) {
    return '0' + num;
  } else {
    return num;
  }
}

export const getSelectorFromName = (name) => {
  return name.includes('#') ? name : `#${name}`;
};
