import model from './model';
import { ExpandModeMainWidgetEvents as Events } from '../../constants/generalConstants';


export default model.createController(
  ({ $w, $widget, flowAPI }) => {

    const handleButtonsDisable = async () => {
      // @ts-expect-error
      const currentIndex = await $w('#proGallery1').currentIndex();
      const isPrevEnabled = currentIndex > 0;
      const isNextEnabled = currentIndex < $w('#proGallery1').items.length - 1;
      if (isPrevEnabled) {
        $w('#buttonPrev').enable();
      } else {
        $w('#buttonPrev').disable();
      }
      if (isNextEnabled) {
        $w('#buttonNext').enable();
      } else {
        $w('#buttonNext').disable();
      }
    };

    const initWidget = async () => {
      // @ts-expect-error
      $w('#proGallery1').navigateToIndex(0, 0);
      $w('#close').onClick(() => {
        $widget.fireEvent(Events.CloseExpandModeClicked, {});
      });
      $w('#buttonPrev').onClick(async () => {
        // @ts-expect-error
        const currentIndex = await $w('#proGallery1').currentIndex() - 1;
        $w('#text1').text = $w('#proGallery1').items[currentIndex]?.title || '';
        // @ts-expect-error
        $w('#proGallery1').navigateToIndex(currentIndex);
        handleButtonsDisable();
      });
      $w('#buttonNext').onClick(async () => {
        // @ts-expect-error
        const currentIndex = await $w('#proGallery1').currentIndex() + 1;

        $w('#text1').text = $w('#proGallery1').items[currentIndex]?.title || '';
        // @ts-expect-error
        $w('#proGallery1').navigateToIndex(currentIndex);
        handleButtonsDisable();
      });
    };

    $widget.onPropsChanged((prevProps, newProps) => {
      const { items, currentIndex } = newProps;
      $w('#proGallery1').items = items;
      if (items?.length > 0 && currentIndex >= 0) {
        if (prevProps.currentIndex !== currentIndex) {
          // @ts-expect-error
          $w('#proGallery1').navigateToIndex(currentIndex, 0);
          $w('#text1').text = $w('#proGallery1').items[currentIndex]?.title || '';
          handleButtonsDisable();
        }
      }
    });

    return {
      pageReady: async () => {
        initWidget();
      },
      exports: {},
    };
  },
);
