import { WidgetPreset } from '@wix/platform-editor-sdk';

export const ExpandModPolyWidgetPresetsArray: WidgetPreset[] = [
  {
    // id: 'externalPreset-#<widgetId>#variants-<variantId>'
    id: 'externalPreset-#8c2ad7ce-6161-4ab6-afbf-d4aec9aef6da#variants-m3cwi2fk1',
    src: 'https://img.freepik.com/free-photo/hand-holding-number-1_53876-63820.jpg',
  },
  {
    src: 'https://static.wixstatic.com/media/85e5b4_334cda4fb9a842538079ee863b88aaf4~mv2.png',
    id: 'externalPreset-#5c093a1a-e43f-4535-8d5d-f2ab729669ad#variants-m3h7rtl9',
  },
  {
    src: 'https://static.wixstatic.com/media/85e5b4_f78357746f094cb0a340936dcb5c7d3e~mv2.png',
    id: 'externalPreset-#5c093a1a-e43f-4535-8d5d-f2ab729669ad#variants-m3li4ndq',
  },
  // {
  //   src: 'https://static.wixstatic.com/media/85e5b4_bbf76682aa4f47f1aa69343f0cf5616e~mv2.png',
  //   id: 'externalPreset-#5c093a1a-e43f-4535-8d5d-f2ab729669ad#variants-m3li4q8a',
  // },
];
