import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { FlowAPI, FlowEditorSDK, WidgetBuilder } from '@wix/yoshi-flow-editor';
import { Logger } from '@wix/yoshi-flow-editor/bi';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';
import { createPolymorphismUtils } from '../../utils/polymorphismUtils';

const setDesktopGFPP = (
  widgetBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  flowAPI: FlowAPI,
  biLogger: Logger,
) => {
  const { t } = flowAPI.translations;
  widgetBuilder
    .gfpp()
    .set('mainAction1', {
      label: t('Fast_Gallery_GFPP_Main_Action_2'),
      onClick: async ({ detail }) => {
        const { componentRef } = detail;
        // TODO: delete this once the new app is ready
        // const galleryId = (
        //   await editorSDK.document.controllers.getData('', {
        //     controllerRef: componentRef,
        //   })
        // ).config.galleryId;

        const { getPresetIdByComponentRef } =
          createPolymorphismUtils(editorSDK);
        const [parentRefComponent] = await editorSDK.components.getAncestors(
          '',
          {
            componentRef,
          },
        );

        const initialSelectedPresetId = await getPresetIdByComponentRef({
          componentRef: parentRefComponent,
        });

        editorSDK.editor.openModalPanel('', {
          url: getPanelUrl('fastGalleryPolyPlugin', 'FastGalleryPresetsPanel'),
          height: '90%' as any,
          width: '90%' as any,
          initialData: {
            componentRef,
            initialSelectedPresetId,
          },
          shouldHideHeader: true,
          centered: true,
        });
        // TODO: fix the BI once the new app is ready
        // biLogger.report(
        //   gallerySettingsPageViewSrc174Evid10({
        //     galleryId,
        //     panelName: 'presets panel',
        //   }),
        // );
      },
    })
    .set('design', {
      target: {
        role: 'fastGallery1',
      },
    })
    .set('settings', {
      behavior: 'HIDE',
    })
    .set('layout', {
      behavior: 'HIDE',
    })
    .set('connect', {
      behavior: 'HIDE',
    })
    .set('widgetPlugins', { behavior: 'HIDE' })
    .set('add', {
      behavior: 'HIDE',
    });
};

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  const biLogger = flowAPI.essentials.biLoggerFactory().logger();
  setDesktopGFPP(widgetBuilder, editorSDK, flowAPI, biLogger);

  widgetBuilder.set({
    displayName: 'Widget fastGalleryPolyPlugin',
    nickname: 'fastGalleryPolyPluginWidget',
  });
};
