import model from './model';
import { FastGalleryPluginEvents as Events } from '../../constants/generalConstants';

export default model.createController(({ $bind, $widget, flowAPI, $w }) => {
  $widget.onPropsChanged((_, newProps) => {
    const { items } = newProps;

    $w('#fastGalleryPolyPluginSlot').slot.items = items;
  });

  return {
    pageReady: async () => {
      $w('#fastGalleryPolyPluginSlot').slot.items = $widget.props.items || []

      $w('#fastGalleryPolyPluginSlot').slot.onGetMoreItems(() => {
        $widget.fireEvent(Events.GetMoreItems, {});
      });
    },
    exports: {},
  };
});
