import { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';

type ResetOverridesAndSetPreset = (params: {
  editorSDK: FlowEditorSDK;
  presetId: string;
  componentRef: ComponentRef;
}) => Promise<void>;

const resetOverridesAndSetPreset: ResetOverridesAndSetPreset = async ({
  editorSDK,
  presetId,
  componentRef,
}): Promise<void> => {
  await editorSDK.components.refComponents.removeAllOverrides('', {
    componentRef,
  });
  await editorSDK.application.appStudioWidgets.changePreset('', {
    componentRef,
    stylePresetId: presetId,
    layoutPresetId: presetId,
  });
};

type IsPresetApplied = (params: {
  editorSDK: FlowEditorSDK;
  presetId: string;
  slotInnerWidgetRef: ComponentRef;
}) => Promise<boolean>;

const isPresetApplied: IsPresetApplied = async ({
  editorSDK,
  presetId,
  slotInnerWidgetRef,
}) => {
  const currentWidgetPreset =
    await editorSDK.application.appStudioWidgets.getPreset('', {
      componentRef: slotInnerWidgetRef,
    });

  if (currentWidgetPreset?.style === presetId) {
    console.log('widget preset already set');
    return true;
  } else {
    return false;
  }
};

export { isPresetApplied, resetOverridesAndSetPreset };
