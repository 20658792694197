import type {
  AppManifest,
  ComponentRef,
  ContextParams,
} from '@wix/platform-editor-sdk';
import {
  EditorReadyFn,
  EditorScriptFlowAPI,
  FlowEditorSDK,
} from '@wix/yoshi-flow-editor';
import { modifyAppManifest } from './editor-app/manifest/modifyAppManifest';
import { onEditorReady } from './editor-app/editorReady';
import { createPolymorphismUtils } from './utils/polymorphismUtils';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefId,
  options,
  flowAPI,
) => {
  return onEditorReady(editorSDK, appDefId, options, flowAPI);
};

export const getAppManifest = async (
  options,
  editorSDK: FlowEditorSDK,
  contextParams: ContextParams,
  flowAPI: EditorScriptFlowAPI,
): Promise<AppManifest> => {
  return modifyAppManifest(options, editorSDK, flowAPI);
};

export const exports = (editorSDK: FlowEditorSDK) => ({
  editor: {
    getSelectedWidgetDesignPresetId: async ({ widgetRef }) => {
      try {
        const { getSlotData, getSlotInnerWidgetData, buildPolyPresetId } =
          createPolymorphismUtils(editorSDK);
        const widgetAncestorRef = (
          await editorSDK.components.getAncestors('', {
            componentRef: widgetRef,
          })
        )[0];
        const slotData = await getSlotData(widgetAncestorRef);
        if (!slotData) {
          return new Error('No slot data found');
        }
        const slotInnerWidgetData = await getSlotInnerWidgetData({ slotData });
        const slotInnerWidgetRef = slotInnerWidgetData?.slotInnerWidgetRef;
        const slotWidgetPluginId = slotInnerWidgetData?.slotWidgetPluginId;

        if (!slotInnerWidgetRef || !slotWidgetPluginId) {
          return new Error('No slot inner widget ref or slot widget plugin id');
        }
        const slotInnerWidgetVariantId = (
          await editorSDK.application.appStudioWidgets.getPreset('', {
            componentRef: slotInnerWidgetRef,
          })
        )?.style;

        const polyPresetId = buildPolyPresetId({
          presetWidgetPluginId: slotWidgetPluginId,
          variantId: slotInnerWidgetVariantId || '',
        });
        return polyPresetId;
      } catch (error) {
        console.error('Error in getSelectedWidgetDesignPresetId', error);
      }
    },
  },
  public: {
    onPresetsChange: async ({
      variationId,
      widgetId,
      componentRef,
    }: {
      variationId: string;
      widgetId: string;
      componentRef: ComponentRef;
    }) => {
      const { changePolyPreset } = createPolymorphismUtils(editorSDK);
      await changePolyPreset({ componentRef, presetId: variationId, widgetId });
    },
  },
});
